<template>
    <nav class="nav">
        <div class="nav__logo oleo" @click="$router.push('/')" style="cursor: pointer">PartnerSelect</div>
        <div class="nav__clock">{{ theTime }}</div>
        <div class="nav__controls" v-if="isLoggedIn">
            <button @click="logout">Uitloggen</button>
        </div>
        <div class="nav__admin" v-if="isLoggedIn && (isAdmin || isMatchmaker)">
            <select name="navigatie" id="navigatie" @change="changeView">
                <option value="Navigatie" selected>Navigatie</option>
                <option value="bemiddelen">Bemiddelen</option>
                <option value="admin">Admin</option>
                <option value="intake">Intakemodule</option>
            </select>
        </div>
    </nav>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: 'NavMenu',
        data() {
            return {
                theTime: '',
            };
        },
        methods: {
            ...mapActions(['logout']),
            updateTime() {
                this.theTime = new Date().toLocaleString('nl-NL', {
                    weekday: 'long',
                    day: 'numeric',
                    month: 'long',
                    hour: 'numeric',
                    minute: 'numeric',
                });
            },
            changeView(evt) {
                const target = evt.target.value;
                if (target === 'bemiddelen') this.$router.push('/');
                if (target === 'admin') this.$router.push('/admin');
                if (target === 'intake') this.$router.push('/intake');
            },
        },
        computed: {
            ...mapGetters(['isLoggedIn', 'isAdmin', 'isMatchmaker']),
        },
        mounted() {
            this.timeInterval = setInterval(this.updateTime, 1000);
        },
        beforeUnmount() {
            clearInterval(this.timeInterval);
        },
    };
</script>

<style lang="scss" scoped>
    .nav {
        width: 100%;
        height: var(--menu-height);
        background: var(--color-primary);
        color: var(--color-white-menu);
        position: sticky;
        top: 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        z-index: 10;
    }

    .nav > div {
        margin: 0 0.5rem;
    }

    .nav__logo {
        font-size: 1.5rem;
    }

    .nav > div.nav__controls {
        margin-left: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;

        & button {
            padding: var(--pad-xs) var(--pad-small);
        }
    }

    .nav #navigatie {
        font-size: 1rem;
        line-height: 1.1;
        padding: var(--pad-xs);
    }
</style>

export default {
    state: {
        fotos: [],
        fotoKlantId: null,
    },
    mutations: {
        SET_FOTOS(state, data) {
            state.fotos = data.fotos;
            state.fotoKlantId = data.klantId;
        },
        CLEAR_FOTOS(state) {
            state.fotos = [];
            state.fotoKlantId = null;
        },
    },
    actions: {
        async setFotos({ commit, rootState }, klant) {
            const fotoKlant = rootState.BemiddelingStore.klanten.filter((e) => e.id === klant.id)[0];
            const profielfoto = fotoKlant?.Profielfoto[0].File_Id__s || '';
            const extraFotos = fotoKlant?.Extra_foto_s?.map((e) => e.File_Id__s) || [];
            const naarState = [profielfoto, ...extraFotos];
            commit('SET_FOTOS', {
                fotos: naarState,
                klantId: klant.id,
            });
            return true;
        },
        clearFotos({ commit }) {
            commit('CLEAR_FOTOS');
        },
    },
    getters: {
        fotomoduleFotos(state) {
            return state.fotos;
        },
        fotoKlantId(state) {
            return state.fotoKlantId;
        },
    },
};

export default {
    state: {
        userCount: 0,
        usersList: [],
        klantClaims: {},
    },
    mutations: {
        SET_USER_COUNT(state, userCount) {
            state.userCount = userCount;
        },
        UPDATE_USERSLIST(state, list) {
            state.usersList = list;
        },
        UPDATE_KLANT_CLAIMS(state, claims) {
            state.klantClaims = claims;
        },
    },
    actions: {
        setUserCount({ commit }, userCount) {
            commit('SET_USER_COUNT', userCount);
        },
        updateUsersList({ commit }, list) {
            commit('UPDATE_USERSLIST', list);
        },
        updateKlantClaims({ commit }, claims) {
            commit('UPDATE_KLANT_CLAIMS', claims);
        },
    },
    getters: {
        userCount(state) {
            return state.userCount;
        },
        usersList(state) {
            return state.usersList;
        },
        klantClaims(state) {
            return state.klantClaims;
        },
    },
};

import router from '@/router';
import axios from '@/config/axios';

export default {
    state: {
        user: null,
    },
    mutations: {
        SET_USER(state, user) {
            state.user = user;
        },
        LOGOUT(state) {
            state.user = null;
        },
    },
    actions: {
        // Login with email and password
        async login({ commit }, auth) {
            const { email, password } = auth;
            try {
                const user = await axios.post('/auth/login', {
                    email: email,
                    password: password,
                });
                commit('SET_USER', user.data);
                // Continue to original URL if linked
                const to = window.sessionStorage.getItem('preLoginRoute');
                if (to) {
                    window.sessionStorage.removeItem('preLoginRoute');
                    router.push(to);
                } else {
                    router.push('/');
                }
                return true;
            } catch (err) {
                // console.error(err);
                return false;
            }
        },
        // Logout user
        async logout({ commit }) {
            axios
                .post('/auth/logout')
                .then(() => {
                    router.push('/login');
                    commit('LOGOUT');
                })
                .catch((err) => console.error(err));
        },
        // Refresh login on return
        async refresh({ commit }) {
            try {
                const user = await axios.post('/auth/refresh');
                if (user.data) {
                    commit('SET_USER', user.data);
                    return user;
                }
                return false;
            } catch (err) {
                console.error(err);
            }
        },
        // Change password
        changePassword() {},
    },
    getters: {
        user(state) {
            return state.user || null;
        },
        isLoggedIn(state) {
            return state.user !== null;
        },
        isGeenKlant(state) {
            return Object.hasOwn(state.user, 'isMatchmaker');
        },
        isAdmin(state) {
            return state.user && state.user.isAdmin;
        },
        isMatchmaker(state) {
            return state.user && state.user.isMatchmaker;
        },
    },
};

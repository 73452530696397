import axios from '@/config/axios';

export default {
    state: {
        preblackLinks: [],
    },
    mutations: {
        SET_PREBLACK_LINKS(state, list) {
            state.preblackLinks = list;
        },
        CLEAR_PREBLACK_LINKS(state) {
            state.preblackLinks = [];
        },
    },
    actions: {
        async haalPreblackLinks({ commit, rootGetters }) {
            try {
                const klantLinksID = rootGetters.currentKlantLinks.id;
                const prelijst = await axios.get(`/zoho/preblack/${klantLinksID}`);
                if (prelijst.data && prelijst.data.length > 0) {
                    const idsGeladenKlanten = rootGetters.klanten.map((e) => e.id);
                    const voorgesteldeKlanten = rootGetters.voorstellenKlantLinks.map((e) => e.ID_Persoon_2?.id || '');
                    const filterLijst = prelijst.data
                        .filter((e) => e !== null && e.ID_Persoon_2)
                        .filter((e) => idsGeladenKlanten.includes(e.ID_Persoon_2.id))
                        .filter((e) => voorgesteldeKlanten.includes(e.ID_Persoon_2.id) === false);

                    commit('SET_PREBLACK_LINKS', filterLijst);
                } else {
                    commit('SET_PREBLACK_LINKS', []);
                }
            } catch (err) {
                console.error('Kan Prelijst niet halen', err);
            }
        },
        clearPreblackLinks({ commit }) {
            commit('CLEAR_PREBLACK_LINKS');
        },
    },
    getters: {
        prelijstLinks(state) {
            return state.preblackLinks;
        },
        blacklistLinks(state) {
            return state.preblackLinks.filter((e) => e.Type_voorstel === 'Black');
        },
        IdsPrelijstLinks(state) {
            return state.preblackLinks.filter((e) => e.Type_voorstel === 'Pre').map((e) => e.ID_Persoon_2?.id);
        },
    },
};

import { createStore } from 'vuex';
import AuthStore from './authStore';
import BemiddelingStore from './bemiddelingStore';
import ModalsStore from './modalStates';
import FilterLinks from './filterLinks';
import FilterRechts from './filterRechts';
import Preblack from './preblackStore';
import SocketStore from './socketStore';
import FotoStore from './fotoStore';
import IntakeStore from './intakeStore';

export default createStore({
    state: {},
    getters: {},
    mutations: {},
    actions: {},
    modules: {
        AuthStore,
        BemiddelingStore,
        ModalsStore,
        FilterLinks,
        FilterRechts,
        Preblack,
        SocketStore,
        FotoStore,
        IntakeStore,
    },
});

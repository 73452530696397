import { voldoetAanEigenschappen, voldoetAanMatchmaker } from '@/utils/filterFunctions';
import axios from 'axios';

export default {
    state: {
        currentFilterLinksSettings: null,
        filterLinks: [],
        bemiddelRichting: 'rechts',
        currentKlant: -1,
        voorstellenKlantLinks: [],
    },
    mutations: {
        SET_FILTER_LINKS_SETTINGS(state, filter) {
            state.currentFilterLinksSettings = filter;
        },
        SET_FILTER_LINKS(state, klanten) {
            state.filterLinks = klanten;
            state.currentKlant = 0;
        },
        CLEAR_FILTER_LINKS(state) {
            state.currentFilterLinksSettings = null;
            state.filterLinks = [];
            state.currentKlant = -1;
            state.voorstellenKlantLinks = [];
        },
        SET_FILTER_LINKS_INDEX(state, newState) {
            const { index, richting } = newState;
            state.currentKlant = index;
            state.richting = richting;
        },
        SET_VOORSTELLEN_KLANT_LINKS(state, voorstellen) {
            state.voorstellenKlantLinks = voorstellen;
        },
        CLEAR_VOORSTELLEN_LINKS(state) {
            state.voorstellenKlantLinks = [];
        },
        REMOVE_FROM_FILTER_LINKS(state, index) {
            const current = state.filterLinks;
            if (current.length > 1) {
                current.splice(index, 1);
                state.filterLinks = current;
            }
        },
    },
    actions: {
        async maakFilterLinks({ commit, dispatch, rootState }, filter) {
            commit('CLEAR_FILTER_LINKS');
            commit('CLEAR_FILTER_RECHTS');
            dispatch('clearPreblackLinks');
            let filterNaarState = null;
            // Filter alleen mensen die ingeschreven staan, niet die ook uitgeschreven maar beschikbaar zijn.
            const actieveKlanten = rootState.BemiddelingStore.klanten.filter(
                (e) => e.Status_inschrijving === 'Ingeschreven'
            );

            // Maak array van eigenschappen die gekozen zijn
            const eigenschappen = Object.entries(filter).reduce((a, c) => {
                if (c.includes(true)) a.push(c[0]);
                return a;
            }, []);
            // Filter op gekozen statussen
            const klantenInStatus = actieveKlanten.filter((e) => {
                return eigenschappen.includes(e.Status_bemiddeling);
            });
            filterNaarState = klantenInStatus;
            // Filter daarna pakketten als hierop gefilterd wordt
            if (['Actief', 'Actief Plus', 'Intensief', 'Exclusief'].some((r) => eigenschappen.includes(r))) {
                const klantenInPakket = klantenInStatus.filter((e) => {
                    if (eigenschappen.includes(e.Pakket)) {
                        return true;
                    } else if (!e.Pakket) {
                        console.log('ZONDER PAKKET ', e.Account_Name);
                    }
                    return false;
                });
                filterNaarState = klantenInPakket;
            }
            // Filter op eigenschappen indien gekozen
            if (filter.filterEigenschappen === true) {
                const klantenMetEigenschappen = voldoetAanEigenschappen(filterNaarState, filter);
                filterNaarState = klantenMetEigenschappen;
            }
            // Filter op matchmaker indien gekozen
            if (filter.filterMatchmaker === true) {
                const klantenVanMatchmaker = voldoetAanMatchmaker(filterNaarState, filter.matchmakerKeuze);
                filterNaarState = klantenVanMatchmaker;
            }

            const filterIds = filterNaarState.map((e) => e.id);
            commit('SET_FILTER_LINKS', filterIds);
            commit('SET_FILTER_LINKS_SETTINGS', filter);
            dispatch('initFilterRechts', null, { root: true });
            await dispatch('haalVoorstellenKlantLinks');
            await dispatch('haalPreblackLinks');
        },
        async andereKlantLinks({ commit, state, getters, dispatch }, input) {
            commit('CLEAR_FILTER_RECHTS');
            commit('CLEAR_VOORSTELLEN_LINKS');
            dispatch('clearPreblackLinks');

            if (
                getters.currentKlantLinks.Status_bemiddeling !== '4.0 Beschikbaar' &&
                getters.currentKlantLinks.Status_bemiddeling !== '4.1 Prioriteit'
            ) {
                dispatch('filterBeschikbaarheidKlantLinks');
            } else {
                let newState = { index: 0, richting: 'rechts' };
                switch (input) {
                    case 'begin':
                        newState.index = 0;
                        newState.richting = 'rechts';
                        break;
                    case 'links':
                        newState.index = state.currentKlant - 1;
                        newState.richting = newState.index > 0 ? 'links' : 'rechts';
                        break;
                    case 'rechts':
                        newState.index = state.currentKlant + 1;
                        newState.richting = newState.index < state.filterLinks.lenghth - 1 ? 'rechts' : 'links';
                        break;
                    case 'einde':
                        newState.index = state.filterLinks.length - 1;
                        newState.richting = 'links';
                        break;
                }
                commit('SET_FILTER_LINKS_INDEX', newState);
            }

            dispatch('initFilterRechts');
            await dispatch('haalVoorstellenKlantLinks');
            await dispatch('haalPreblackLinks');
        },
        async haalVoorstellenKlantLinks({ commit, state }) {
            // TODO: Debounce bij snel klikken
            const id = state.filterLinks[state.currentKlant];
            const voorstellen = await axios.get(`/zoho/voorstellen/${id}`);
            const voorstellenGesorteerd = voorstellen.data.sort((a, b) => {
                return new Date(a.Datum_voorstel) > new Date(b.Datum_voorstel) ? -1 : 1;
            });
            commit('SET_VOORSTELLEN_KLANT_LINKS', voorstellenGesorteerd);
        },
        filterBeschikbaarheidKlantLinks({ commit, state, dispatch, rootGetters }) {
            const beschikbaarIDs = rootGetters.actiefBemiddelbareKlanten;
            const idCurrentKlant = state.filterLinks[state.currentKlant];
            if (beschikbaarIDs.includes(idCurrentKlant) === false) {
                commit('REMOVE_FROM_FILTER_LINKS', state.currentKlant);
                if (state.filterLinks.length > 1) {
                    dispatch('filterBeschikbaarheidKlantLinks');
                }
            }
        },
        updateVoorstellenLinks({ commit, state, getters }, voorstel) {
            if (voorstel.ID_Persoon_1 === getters.currentKlantLinks.id) {
                const voorstellen = [...state.voorstellenKlantLinks];
                voorstellen.push(voorstel);
                const voorstellenGesorteerd = voorstellen.sort((a, b) => {
                    return new Date(a.Datum_voorstel) > new Date(b.Datum_voorstel) ? -1 : 1;
                });
                commit('SET_VOORSTELLEN_KLANT_LINKS', voorstellenGesorteerd);
            }
        },
        setSpecifiekeKlantLinks({ commit, dispatch }, klanten) {
            commit('SET_FILTER_LINKS', klanten);
            dispatch('initFilterRechts');
            dispatch('haalVoorstellenKlantLinks');
            dispatch('haalPreblackLinks');
        },
    },
    getters: {
        currentFilterSettings(state) {
            return state.currentFilterLinksSettings;
        },
        aantalKlantenInFilterLinks(state) {
            return state.filterLinks.length;
        },
        currentKlantLinks(state, getters, rootState) {
            if (state.currentKlant === -1) {
                return {};
            }
            return rootState.BemiddelingStore.klanten.reduce((a, c) => {
                c.id === state.filterLinks[state.currentKlant] ? (a = c) : a;
                return a;
            }, {});
        },
        currentKlantLinksIndex(state) {
            return state.currentKlant;
        },
        voorstellenKlantLinks(state) {
            return state.voorstellenKlantLinks || [];
        },
        voorgesteldeKlantIdsLinks(state) {
            return state.voorstellenKlantLinks.map((e) => e.ID_Persoon_2?.id || '');
        },
    },
};

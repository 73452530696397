import { io } from 'socket.io-client';

export default (app, options) => {
    const opts = {
        url: process.env.NODE_ENV === 'production' ? 'https://psapi.partnerselect.net' : 'http://localhost:3000',
    };

    if (options) {
        for (let [k, v] of Object.entries(options)) {
            opts[k] = v;
        }
    }

    app.config.globalProperties.$socket = io(opts.url, {
        withCredentials: true,
        autoConnect: false,
    });
};

/**
 * Zoek index van een klant in een lijst
 * @param {string} id ID van klant
 * @param {Array} filter Array te doorzoeken
 * @returns {number}
 */
export const klantIndexById = (id, filter) => {
    const index = filter.reduce((a, c, i) => {
        return c.id === id ? (a = i) : a;
    }, null);
    return index;
};

/**
 * Valt de klant binnen aangegeven reisafstand?
 * @param {Object} klant Klantobject
 * @param {Object} filter Ingesteld filter
 * @param {Object} postcodes Lijst met postcodes van klant
 * @returns {Boolean}
 */
export const voldoetAanAfstand = (klant, filter, postcodes) => {
    const { kilometers, minuten } = filter;
    const klantPostcode = klant.Postcode ? klant.Postcode.substr(0, 4) : '0000';

    const postcodeBekend = Object.hasOwnProperty.call(postcodes, klantPostcode);
    const afstandKilometers = postcodes[klantPostcode]?.km || -1;
    const afstandMinuten = postcodes[klantPostcode]?.minuten || -1;

    if (postcodeBekend && postcodes[klantPostcode] && afstandKilometers > kilometers && afstandMinuten > minuten) {
        return false;
    }

    return true;
};

/**
 * Checkt of de persoon voldoet aan het ingevoerde zoekfilter en getoond kan worden
 * @param {Object} persoon Persoon voor wie gezocht wordt
 * @param {Object} filter Zoekfilter
 * @param {string[]} reedsVoorgesteld ID array van voorgestelde personen
 * @param {string[]} blacklistVoorstellen ID array van geblackliste personen
 * @returns {Boolean}
 */
export const voldoetAanFilter = (persoon, filter, reedsVoorgesteld, blacklistVoorstellen) => {
    // Primaire check op NULL waarden in persoon
    if (
        [
            persoon.Geslacht,
            persoon.Leeftijd_exact,
            persoon.Lengte,
            persoon.Opleiding,
            persoon.Postuur,
            filter.werkzaam && filter.werkzaam !== 'onbelangrijk' ? persoon.Werkzaam : 'ok',
            filter.kinderen && filter.kinderen !== 'onbelangrijk' ? persoon.Kinderen : 'ok',
            filter.kinderwens && filter.kinderwens !== 'bespreekbaar' ? persoon.Kinderwens : 'ok',
            filter.huisdieren && filter.huisdieren !== 'onbelangrijk' ? persoon.Huisdieren : 'ok',
            filter.roken && filter.roken !== 'onbelangrijk' ? persoon.Roken : 'ok',
            filter.geloof && filter.geloof !== 'onbelangrijk' ? persoon.Geloof : 'ok',
            filter.burgerlijke_staat &&
            filter.burgerlijke_staat.length > 0 &&
            filter.burgerlijke_staat !== 'onbelangrijk'
                ? persoon.Burgerlijke_staat
                : 'ok',
        ].includes(null)
    ) {
        const nullObject = {
            Geslacht: persoon.Geslacht,
            Leeftijd_exact: persoon.Leeftijd_exact,
            Lengte: persoon.Lengte,
            Opleiding: persoon.Opleiding,
            Postuur: persoon.Postuur,
            Werkzaam: persoon.Werkzaam,
            Kinderen: persoon.Kinderen,
            Kinderwens: persoon.Kinderwens,
            Huisdieren: persoon.Huisdieren,
            Roken: persoon.Roken,
            Geloof: persoon.Geloof,
            Burgerlijke_staat: persoon.Burgerlijke_staat,
        };
        const nullVelden = Object.entries(nullObject).reduce((a, c) => {
            if (c[1] === null) {
                a.push(c[0]);
            }
            return a;
        }, []);
        console.warn(`NULL Waarde gevonden in ${persoon.Account_Name} (${nullVelden.join(',')})`);
    }

    // Niet aan zichzelf voorstellen
    if (filter.id === persoon.id) {
        return false;
    }

    // Niet tonen indien reeds voorgesteld
    if (reedsVoorgesteld.includes(persoon.id)) {
        return false;
    }

    // Niet voorstellen aan geblackliste voorstellen
    if (blacklistVoorstellen.includes(persoon.id)) {
        return false;
    }

    // Geslacht
    if (filter.geslacht && persoon.Geslacht.toLowerCase() !== filter.geslacht) {
        return false;
    }

    // Leeftijd
    if (persoon.Leeftijd_exact < filter.leeftijd_van || persoon.Leeftijd_exact > filter.leeftijd_tot) {
        return false;
    }
    // Lengte
    if (persoon.Lengte < filter.lengte_van || persoon.Lengte > filter.lengte_tot) {
        return false;
    }
    // Niveau
    const niveauArray = Object.entries(filter.niveau)
        .map((e) => (e[1] ? e[0] : false))
        .filter((e) => e !== false);
    if (
        niveauArray.length > 0 &&
        persoon.Opleiding &&
        niveauArray.includes(persoon.Opleiding.toLowerCase()) === false
    ) {
        return false;
    }
    // Postuur
    const postuurArray = Object.entries(filter.postuur)
        .map((e) => (e[1] ? e[0] : false))
        .map((e) => (e === 'zwaar' ? 'zwaar / obese' : e))
        .filter((e) => e !== false);
    if (postuurArray.length > 0 && persoon.Postuur && postuurArray.includes(persoon.Postuur.toLowerCase()) === false) {
        return false;
    }

    // Huidskleur
    const huidskleurArray = Object.entries(filter.huidskleur)
        .map((e) => (e[1] ? e[0] : false))
        .filter((e) => e !== false);
    if (
        huidskleurArray.length > 0 &&
        persoon.Huidskleur &&
        huidskleurArray.includes(persoon.Huidskleur.toLowerCase()) === false
    ) {
        return false;
    }

    // Werkzaam
    if (filter.werkzaam && filter.werkzaam !== 'onbelangrijk' && persoon.Werkzaam.toLowerCase() !== filter.werkzaam) {
        return false;
    }

    // Kinderen
    if (filter.kinderen && filter.kinderen !== 'onbelangrijk' && persoon.Kinderen.toLowerCase() !== filter.kinderen) {
        return false;
    }

    // Kinderwens
    if (
        filter.kinderwens &&
        filter.kinderwens !== 'bespreekbaar' &&
        filter.kinderwens !== persoon.Kinderwens.toLowerCase()
    ) {
        return false;
    }

    // Huisdieren
    if (
        filter.huisdieren &&
        filter.huisdieren !== 'onbelangrijk' &&
        persoon.Huisdieren.toLowerCase() !== filter.huisdieren
    ) {
        return false;
    }

    // Roken
    if (persoon.Roken && filter.roken && filter.roken !== 'onbelangrijk') {
        if (filter.roken === 'moet' && persoon.Roken.toLowerCase() === 'nee') {
            return false;
        }
        if (filter.roken === 'matig' && persoon.Roken.toLowerCase() === 'veel') {
            return false;
        }
        if (filter.roken === 'nee' && persoon.Roken.toLowerCase() !== 'nee') {
            return false;
        }
    }

    // Geloof
    if (
        persoon.Geloof &&
        filter.geloof &&
        filter.geloof !== 'onbelangrijk' &&
        filter.geloof !== persoon.Geloof.toLowerCase()
    ) {
        return false;
    }

    // Burgerlijke staat
    if (
        persoon.Burgerlijke_staat &&
        filter.burgerlijke_staat &&
        filter.burgerlijke_staat !== 'onbelangrijk' &&
        filter.burgerlijke_staat !== null
    ) {
        if (filter.burgerlijke_staat === 'gescheiden' && persoon.Burgerlijke_staat.toLowerCase() !== 'gescheiden') {
            return false;
        }
        if (filter.burgerlijke_staat === 'weduwe' && persoon.Burgerlijke_staat.toLowerCase() !== 'weduw(e)(naar)') {
            return false;
        }
        if (
            filter.burgerlijke_staat === 'ongehuwd' &&
            (persoon.Burgerlijke_staat.toLowerCase() === 'gescheiden' ||
                persoon.Burgerlijke_staat.toLowerCase() === 'weduw(e)(naar)')
        ) {
            return false;
        }
    }

    return true;
};

/**
 * Filtert op aangegeven eigenschappen in het filter
 * @param {Object[]} lijst Huidige lijst klanten te filteren
 * @param {Object} filter Huidig filter
 * @returns {Array}
 */
export const voldoetAanEigenschappen = (lijst, filter) => {
    let klantenMetEigenschappen = [...lijst];

    const geslachtEigenschap = [];
    if (filter.Man) geslachtEigenschap.push('Man');
    if (filter.Vrouw) geslachtEigenschap.push('Vrouw');

    const niveauEigenschap = [];
    if (filter.LBO) niveauEigenschap.push('LBO');
    if (filter.MBO) niveauEigenschap.push('MBO');
    if (filter.HBO) niveauEigenschap.push('HBO');
    if (filter.WO) niveauEigenschap.push('WO');

    // Indien geen selectie, geen lijst
    if (geslachtEigenschap.length === 0 && niveauEigenschap.length === 0) {
        return [];
    }

    if (geslachtEigenschap.length > 0) {
        const klantMetGeslacht = klantenMetEigenschappen.filter((e) => {
            return geslachtEigenschap.includes(e.Geslacht);
        });
        klantenMetEigenschappen = klantMetGeslacht;
    }

    if (niveauEigenschap.length > 0) {
        const klantMetNiveau = klantenMetEigenschappen.filter((e) => {
            return niveauEigenschap.includes(e.Opleiding);
        });
        klantenMetEigenschappen = klantMetNiveau;
    }
    return klantenMetEigenschappen;
};

export const voldoetAanMatchmaker = (lijst, filter) => {
    const vanMatchmakers = lijst.filter((e) => {
        return filter.includes(e.Matchmaker?.name) ? true : false;
    });
    return vanMatchmakers;
};

<template>
    <div class="fullscreen-loader">
        <div class="circle">
            <div class="line line1">
                <div class="line line2">
                    <div class="line line3">
                        <div class="line line4"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="text">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'LoadSpinner',
    };
</script>

<style lang="scss" scoped>
    .fullscreen-loader {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: var(--color-white);
        z-index: 10;

        & > div {
            margin-bottom: 1rem;
        }
    }

    .circle {
        position: relative;
        width: 75px;
        height: 75px;
        margin: 2px;
    }

    .line {
        box-sizing: border-box;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 4px solid transparent;
        border-top: 4px solid var(--color-primary);
        border-right: 4px solid var(--color-primary);
        padding: 3px;
        height: 100%;
        width: 100%;
        border-radius: 50%;
        animation: circlespin 2s linear infinite;
    }

    .line2 {
        animation-delay: 0.3s;
    }
    .line4 {
        animation-delay: 0.7s;
    }

    @keyframes circlespin {
        0% {
            transform: rotate(0deg);
        }
        50% {
            transform: rotate(180deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
</style>

import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import Tooltip from 'vue-follow-tooltip';
import './css/main.scss';
import Socket from './plugins/Socket';
import { PDFPlugin } from 'vue3-pdfmake';

// Global components
import Spinner from '@/components/global/Spinner.vue';
import Modal from '@/components/global/Modal.vue';

const app = createApp(App);
app.use(store);
app.use(router);
app.use(Toast, {});
app.use(Tooltip, { delay: '200ms' });
app.use(Socket);
app.use(PDFPlugin);
app.component('Spinner', Spinner);
app.component('Modal', Modal);

app.config.globalProperties.$baseUrl =
    process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : 'https://psapi.partnerselect.net';

app.mount('#app');

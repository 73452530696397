export default {
    state: {
        klantenInStatusModalShown: false,
        maakFilterLinksModalShown: false,
        maakFilterRechtsModalShown: false,
        vergelijkerModalShown: false,
        maakVoorstelModalShown: false,
        bemiddelpuntenModalShown: false,
        preblackModalShown: false,
        fotomoduleModalShown: false,
    },
    mutations: {
        TOGGLE_KLANTEN_IN_STATUS_MODAL(state) {
            state.klantenInStatusModalShown = !state.klantenInStatusModalShown;
        },
        TOGGLE_FILTER_LINKS_MODAL(state) {
            state.maakFilterLinksModalShown = !state.maakFilterLinksModalShown;
        },
        TOGGLE_FILTER_RECHTS_MODAL(state) {
            state.maakFilterRechtsModalShown = !state.maakFilterRechtsModalShown;
        },
        TOGGLE_VERGELIJKER_MODAL(state) {
            state.vergelijkerModalShown = !state.vergelijkerModalShown;
        },
        TOGGLE_MAAK_VOORSTEL_MODAL(state) {
            state.maakVoorstelModalShown = !state.maakVoorstelModalShown;
        },
        TOGGLE_BEMIDDELPUNTEN_MODAL(state) {
            state.bemiddelpuntenModalShown = !state.bemiddelpuntenModalShown;
        },
        TOGGLE_PREBLACK_MODAL(state) {
            state.preblackModalShown = !state.preblackModalShown;
        },
        TOGGLE_FOTOMODULE_MODAL(state) {
            state.fotomoduleModalShown = !state.fotomoduleModalShown;
        },
    },
    actions: {
        toggleKlantenInStatusModal({ commit }) {
            commit('TOGGLE_KLANTEN_IN_STATUS_MODAL');
        },
        toggleMaakFilterLinksModal({ commit }) {
            commit('TOGGLE_FILTER_LINKS_MODAL');
        },
        toggleMaakFilterRechtsModal({ commit }) {
            commit('TOGGLE_FILTER_RECHTS_MODAL');
        },
        toggleVergelijkerModal({ commit }) {
            commit('TOGGLE_VERGELIJKER_MODAL');
        },
        toggleMaakVoorstelModal({ commit }) {
            commit('TOGGLE_MAAK_VOORSTEL_MODAL');
        },
        toggleBemiddelpuntenModal({ commit }) {
            commit('TOGGLE_BEMIDDELPUNTEN_MODAL');
        },
        togglePreblackModal({ commit }) {
            commit('TOGGLE_PREBLACK_MODAL');
        },
        toggleFotomoduleModal({ commit }) {
            commit('TOGGLE_FOTOMODULE_MODAL');
        },
    },
    getters: {
        klantenInStatusModal(state) {
            return state.klantenInStatusModalShown;
        },
        filterLinksModal(state) {
            return state.maakFilterLinksModalShown;
        },
        filterRechtsModal(state) {
            return state.maakFilterRechtsModalShown;
        },
        vergelijkerModal(state) {
            return state.vergelijkerModalShown;
        },
        maakVoorstelModal(state) {
            return state.maakVoorstelModalShown;
        },
        bemiddelpuntenModal(state) {
            return state.bemiddelpuntenModalShown;
        },
        preblackModal(state) {
            return state.preblackModalShown;
        },
        fotomoduleModal(state) {
            return state.fotomoduleModalShown;
        },
    },
};

<template>
    <NavMenu />
    <router-view />
</template>

<script>
    import NavMenu from '@/components/Menu.vue';

    export default {
        name: 'HomeView',
        data() {
            return {};
        },
        components: {
            NavMenu,
        },
    };
</script>

<style lang="scss">
    #app {
        font-family: 'Open Sans', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: var(--color-black);
        background: var(--color-white);
        min-height: 100vh;
    }

    nav {
        padding: 30px;

        a {
            font-weight: bold;
            color: #2c3e50;

            &.router-link-exact-active {
                color: #42b983;
            }
        }
    }
</style>
